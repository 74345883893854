










import { Component } from 'vue-property-decorator';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';

@Component
export default class PageHeaderWidget extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  created(): void {
    this.setDataConfig();
  }
}
